/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ADMIN_ROLES } from "@roadflex/constants";
import { AdminType } from "@roadflex/types";
import { FormikProps } from "formik";
import { Dialog } from "primereact/dialog";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { CustomMultiSelect } from "../../dropdowns";
import { TextInput } from "../../inputs";

type CreateUpdateAdminModalProps = FormikProps<AdminType> & {
  mode: string;
  showAdminModal: boolean;
  setShowAdminModal: (value: boolean) => void;
};
export const CreateUpdateAdminModal = ({
  mode,
  showAdminModal,
  setShowAdminModal,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  resetForm,
  errors,
  touched,
}: CreateUpdateAdminModalProps) => {
  const footer = (
    <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
      {mode === "create" && (
        <Button
          className=""
          type={ButtonType.Submit}
          size={ButtonSize.AppSize}
          disabled={isSubmitting}
          variant={ButtonVariant.AppOrange}
        >
          Create
        </Button>
      )}
      {mode === "update" && (
        <Button
          className=""
          type={ButtonType.Submit}
          size={ButtonSize.AppSize}
          disabled={isSubmitting}
          variant={ButtonVariant.AppOrange}
        >
          Update
        </Button>
      )}
      <Button
        className="mr-2"
        type={ButtonType.Button}
        size={ButtonSize.AppSize}
        variant={ButtonVariant.Secondary}
        disabled={isSubmitting}
        onClick={() => setShowAdminModal(false)}
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <Dialog
      style={{ width: "60vw", fontFamily: "Inter" }}
      header={mode === "create" ? `Create Admin` : `Update Admin`}
      visible={showAdminModal}
      footer={<></>}
      onHide={() => setShowAdminModal(false)}
    >
      <div className="flex items-end justify-center px-4 pt-4 pb-20 sm:block sm:p-0">
        <form onSubmit={handleSubmit}>
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <TextInput
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  variant="medium"
                  disabled={mode === "update"}
                  onBlur={handleBlur}
                  touched={touched.firstName}
                  error={Boolean(errors?.firstName) && touched.firstName}
                  errorMessage={errors?.firstName}
                ></TextInput>
              </div>
              <div className="col-span-12">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  variant="medium"
                  disabled={mode === "update"}
                  onBlur={handleBlur}
                  touched={touched.lastName}
                  error={Boolean(errors?.lastName) && touched.lastName}
                  errorMessage={errors?.lastName}
                ></TextInput>
              </div>

              <div className="col-span-12">
                <TextInput
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  variant="medium"
                  disabled={mode === "update"}
                  onBlur={handleBlur}
                  touched={touched.email}
                  error={Boolean(errors?.email) && touched.email}
                  errorMessage={errors?.email}
                ></TextInput>
              </div>

              <div className="col-span-12">
                <CustomMultiSelect
                  label="Roles"
                  value={values.roles}
                  options={ADMIN_ROLES}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => {
                    setFieldValue("roles", e.value);
                  }}
                  maxSelectedLabels={1}
                ></CustomMultiSelect>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {mode === "create" && (
              <Button
                className=""
                type={ButtonType.Submit}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
                variant={ButtonVariant.AppOrange}
              >
                Create
              </Button>
            )}
            {mode === "update" && (
              <Button
                className=""
                type={ButtonType.Submit}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
                variant={ButtonVariant.AppOrange}
              >
                Update
              </Button>
            )}
            <Button
              className="mr-2"
              type={ButtonType.Button}
              size={ButtonSize.AppSize}
              variant={ButtonVariant.Secondary}
              disabled={isSubmitting}
              onClick={() => setShowAdminModal(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
