/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  READ_ADDITIONAL_MERCHANT_CATEGORIES,
  READ_BILLING_CYCLE,
  READ_CARDHOLDER_STATUS,
  READ_USER_DETAILS,
  UPDATE_ADDITIONAL_MERCHANT_CATEGORIES,
  USER_UPDATE_BY_ADMIN,
  WAIVE_LATE_FEES,
} from "@roadflex/graphql";
import { useFormik } from "formik";
import * as Yup from "yup";

type Values = {
  updateSetting: string;
  id: string | null;
  userLeadId: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  ssn: string;
  business: any | null;
  setting: any | null;
  referralSetting: any | null;
  fleetLicense: string;
  statements: string[];
  irsLetter: string;
};

type useUserUpdateByAdminProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

type UseUpdateAdditionalMerchantCategoryProps = {
  initialValues: {
    email: string;
    additionalCategory: string;
  };
  onSubmit: (val: any, err: any) => void;
};

type UseUpdateLateFeesProps = {
  initialValues: {
    billingCycleId: string;
    waiveLateFees: string;
  };
  validationSchema: Yup.AnyObjectSchema;
  onSubmit: (val: any, err: any) => void;
};

export function useUserUpdateByAdmin({
  onSubmit,
  initialValues,
}: useUserUpdateByAdminProps) {
  const [UserUpdateByAdminFn] = useMutation(USER_UPDATE_BY_ADMIN, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange: handleUpdateUserByAdminChange,
    handleSubmit: handleUpdateUserByAdminSubmit,
    values: updateUserByAdminValues,
    isSubmitting: isUpdateUserByAdminSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await UserUpdateByAdminFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        // console.log(error);
        onSubmit(null, error);
      }
    },
  });

  return {
    updateUserByAdminValues,
    handleUpdateUserByAdminChange,
    handleUpdateUserByAdminSubmit,
    isUpdateUserByAdminSubmitting,
    ...rest,
  };
}

export function useReadAdditionalMerchantCategory(email: string) {
  const { data, ...rest } = useQuery<{
    readAdditionalMerchantCategories: {
      additionalCategory: string;
      code: string;
      message: string;
    };
  }>(READ_ADDITIONAL_MERCHANT_CATEGORIES, {
    variables: {
      data: {
        email,
      },
    },
    fetchPolicy: "no-cache",
  });
  return { data, ...rest };
}

export function useUpdateAdditionalMerchantCategory({
  onSubmit,
  initialValues,
}: UseUpdateAdditionalMerchantCategoryProps) {
  const [updateAdditionalMerchantCategoriesFn] = useMutation<{
    updateAdditionalMerchantCategories: {
      code: string;
      message: string;
    };
  }>(UPDATE_ADDITIONAL_MERCHANT_CATEGORIES, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const { setFieldValue, handleSubmit, values, ...rest } = useFormik({
    initialValues,
    onSubmit: async (input) => {
      const data = { ...input };
      try {
        const res = await updateAdditionalMerchantCategoriesFn({
          variables: { data },
        });
        if (res?.data?.updateAdditionalMerchantCategories) {
          onSubmit(res?.data?.updateAdditionalMerchantCategories, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    setFieldValue,
    handleSubmit,
    values,
    ...rest,
  };
}

export function useUpdateLateFees({
  onSubmit,
  initialValues,
  validationSchema,
}: UseUpdateLateFeesProps) {
  const [UpdateLateFeesFn] = useMutation<{
    waiveLateFees: {
      code: string;
      message: string;
    };
  }>(WAIVE_LATE_FEES, {
    refetchQueries: [READ_BILLING_CYCLE, READ_CARDHOLDER_STATUS],
  });

  const { setFieldValue, handleSubmit, values, ...rest } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input) => {
      const data = {
        ...input,
        waiveLateFees: Number(input.waiveLateFees) * 100,
      };
      try {
        const res = await UpdateLateFeesFn({
          variables: { data },
        });
        if (res?.data?.waiveLateFees) {
          onSubmit(res?.data?.waiveLateFees, null);
        }
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });
  return {
    setFieldValue,
    handleSubmit,
    values,
    ...rest,
  };
}
