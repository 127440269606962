import { ModalLoadingLargeSvg } from "@roadflex/react-svg";
import React from "react";

type AdminModalLoaderProps = {
  loadingText?: React.ReactNode;
  showLoading: boolean;
};

export const AdminModalLoader: React.FC<AdminModalLoaderProps> = ({
  loadingText = (
    <div className="absolute z-10 flex flex-col items-center gap-6 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <ModalLoadingLargeSvg />
      <div className="text-3xl font-extrabold">Loading, do not refresh!</div>
    </div>
  ),
  showLoading,
}) => (
  <div className="flex flex-col items-center justify-center space-y-2">
    {showLoading && (
      <div className="spinner-border" role="status">
        {loadingText}
      </div>
    )}
  </div>
);

export default AdminModalLoader;
